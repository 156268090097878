import * as React from "react"
import * as axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container, Row, Col, Table } from 'react-bootstrap';

import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// styles
const pageStyles = {
  color: "#232129",
  padding: 10,
  fontFamily: "sans-serif",
  background: "#f3f3f3"
}

const RowRevSumary = {
  marginBottom: 5,
  paddingBottom: 5,
  borderBottom: "1px dashed #ccc"
}

const ColRevSumary = {
  flexGrow: 1,
  flexBasis: "60%"
}

const HeadingLeft = {
  fontWight : "bold",
  fontSize : "15px",
  marginTop: "-3px"
}

const SubHeadingLeft = {
  marginBottom: 0,
  fontSize: "9px"
}

const TotalRight = {
  fontSize: "14px",
  fontWight: "bold"
}

const VerticalMiddleCol = {
  "display": "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignContent: "center",
  justifyContent: "flex-end",
  alignItems: "center",
  flexGrow: 1,
  flexBasis: "40%"
}

const ContainerShadowCard = {
  "background": "#ffffff",
  "padding": "20px",
  paddingBottom: "10px",
  paddingTop: "10px",
  borderRadius: "0.4em",
  "border": "1px solid #e6e6e6",
  boxShadow: "0px 0px 21px 2px #ccc"
}

const ContainerShadowCardMt = {
  "background": "#ffffff",
  "padding": "20px",
  paddingBottom: "10px",
  paddingTop: "10px",
  borderRadius: "0.4em",
  "border": "1px solid #e6e6e6",
  boxShadow: "0px 0px 21px 2px #ccc",
  marginTop: "10px"
}

const StyleDefaultTheadTHFCH1 = {
  "vertical-align": "middle",
  "font-size": "12px",
  "padding": "5px",
  "border-bottom": "1px solid #ccc",
  "text-align": "center",
  "width": "10px"
}

const StyleDefaultTheadTHFCH = {
  "vertical-align": "middle",
  "font-size": "12px",
  "padding": "5px",
  "border-bottom": "1px solid #ccc",
  "text-align": "center",
  "width": "40%"
}

const StyleDefaultTheadTH = {
  // style="vertical-align: middle; font-size: 12px; padding: 5px;"

  "vertical-align": "middle",
  "font-size": "12px",
  "padding": "5px",
  "border-bottom": "1px solid #ccc",
  "text-align": "center"
}

const StyleDefaultTbodyTD = {
  // style="vertical-align: middle; font-size: 12px; padding: 5px;"

  "vertical-align": "middle",
  "font-size": "12px",
  "padding": "5px",
  "border-bottom": "1px solid #ccc",
  "text-align": "center"
}

const HeaderSummaryConcurrent = {
  fontSize: "11px",
  fontWight: "bold",
  "color": "#af0000",
  marginBottom: "10px"
}

const RowSummaryConcurrent = {

}

const ColSummaryConcurrent = {
  "display": "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center"
}

const ColSummaryConcurrentLast = {
  "display": "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  "width": "39px",
  "flex": "auto"
}

const HeaderColSummaryConcurrent = {
  fontSize: "11px",
  fontWight: "500"
}

const ContentColSummaryConcurrent = {
  fontSize: "20px"
}

const MT10 = {
  marginTop: 10,
}
const MB10 = {
  marginBottom: 10,
}

const StyleApiUsed = {
  fontSize: "12px"
}

const StyleApiUsedRight = {
  fontSize: "12px",
  textAlign: "right"
}

const Table3ColTd1 = {
  verticalAlign: "middle",
  fontSize: "12px",
  "padding": "5px"
}

const Table3ColTd2 = {
  verticalAlign: "middle",
  fontSize: "12px",
  "padding": "5px"
}

const Table3ColTd3 = {
  verticalAlign: "middle",
  fontSize: "13px",
  "padding": "5px",
  textAlign: "right",
  fontWight: "bold"
}

const StyTable = {
  paddingBottom: "0px",
  "margin": "0px"
}

const BoxLogin = {
  padding: "20px",
  background: "white",
  borderRadius: "0.5em",
  boxShadow: "0px 0px 23px -7px #ccc",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "stretch",
  border: "1px solid #f0f0f0"
}

const BoxLoginH1 = {
  fontSize: "14px",
  fontWeight: "bold"
}

const BoxLoginInput = {
  fontSize: "13px",
  padding: "7px",
  color: "black",
  marginBottom: "10px"
}

const FrameButtonLogin = {

}

const ButtonLogin = {
  display: "block",
  width: "100%",
  background: "#ff7a00",
  border: "1px solid #ff6300",
  color: "white",
  padding: "8px",
  borderRadius: "0.5em",
  marginTop: "10px",
  fontWeight: "bold",
  fontSize: "13px"
}

const FrameBoxLogin = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  fontFamily: "sans-serif",
  background: "rgb(193,193,193)",
  background: "linear-gradient(177deg, rgb(255 255 255) 0%, rgb(255 225 197) 100%)"
}

const LogoLoginBox = {
  width: "150px",
  margin: "0 auto",
  marginBottom: "20px"
}

const ToastContainerStyle = {

}

const baseURL = "https://core.watzap.id/restricted_req/get_report_apps.php";
// const baseURL = "http://localhost/wabot/api/bangjago/restricted_req/get_report_apps.php";
// const baseURL = "https://bangjago.core.watzap.id/restricted_req/get_report_apps.php";

const LastKnownOnlineStatusURL = "https://hulkcore.watzap.id/get_last_known_online_cs";

// markup
const IndexPage = () => {
  const [post, setPost] = React.useState(null);
  const [lastknownonline, setLastKnownOnline] = React.useState(null);

  const [uuid, setUUId] = React.useState('');
  const [name, setName] = React.useState('');
  const [pwd, setPwd] = React.useState('');

  const [isloaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    var isHitted = false;
    if((uuid == '') && !isHitted){
      isHitted = true;
      setUUId(window.localStorage.getItem('UUID'));
    }

    if(!isloaded){
      setLoaded(true)
      axios.get(baseURL).then((response) => {
        setPost(response.data);
      });

      const lastKnownParams = JSON.stringify({ secret_key: '^Ummj6TlThfmnREWO2Hwgxa0kR0jYKRyL3IORE' });
      const lastKnownConfig = {
          headers: {
          'Content-Type': 'application/json'
          }
      };
      axios.post(LastKnownOnlineStatusURL, lastKnownParams, lastKnownConfig).then((response) => {
        setLastKnownOnline(response.data);
      });
    }
    
  }, [isloaded])
  
  

  console.log('UUID L STORAGE', uuid);

  const handle = () => {

    // console.log('Handler', 'Hitted');

    if(uuid == null){
      if((name != '') && (pwd != '')){
            if((name == 'reporting@watzap.id') && (pwd == 'P9xpD@MTVS$=_DUQnmF=Sen%Nx7H8S')){

              toast.info("Login Successfully")
        
              localStorage.setItem('UUID', name);
              setUUId(name)
        
              window.location.reload();
              
            }else{
              toast.error("Invalid Username / Password", {position: "bottom-center"})
            }
        }
    }
    
    
  };

  var isRequested = false;

  // if (!post && ((uuid != '') && (uuid != null)) && !isRequested) {

  //   isRequested = true;

  //   axios.get(baseURL).then((response) => {
  //     setPost(response.data);
  //   });
    
  //   const lastKnownParams = JSON.stringify({ secret_key: '^Ummj6TlThfmnREWO2Hwgxa0kR0jYKRyL3IORE' });
  //   const lastKnownConfig = {
  //       headers: {
  //       'Content-Type': 'application/json'
  //       }
  //   };
  //   axios.post(LastKnownOnlineStatusURL, lastKnownParams, lastKnownConfig).then((response) => {
  //     setLastKnownOnline(response.data);
  //   });
  // }


  if (uuid == null) return(
    <main>
      <title>WatZap</title>
      <div style={FrameBoxLogin}>
        <div style={BoxLogin}>
            <img src="https://core.watzap.id/assets/img/logo-wtz.png" style={LogoLoginBox}/>
            <h4 style={BoxLoginH1}>Username:</h4>
            <input placeholder="Username" value={name} onChange={(e) => setName(e.target.value)} style={BoxLoginInput}/>
            <h4 style={BoxLoginH1}>Password:</h4>
            <input type="password" placeholder="Password" value={pwd} onChange={(e) => setPwd(e.target.value)} style={BoxLoginInput}/>
            <div style={FrameButtonLogin}>
              <button onClick={handle} style={ButtonLogin}>Login</button>
            </div>
        </div>
      </div>
      <ToastContainer style={ToastContainerStyle}/>
    </main>
  );

  if (!post && (uuid != null)) return(
    <main style={pageStyles}>
      <title>WatZap Report Apps</title>

      <Container style={ContainerShadowCard} fluid="md">
        <ReactPlaceholder style={MT10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder style={MB10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <ReactPlaceholder style={MT10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder style={MB10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <ReactPlaceholder style={MT10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder style={MB10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <ReactPlaceholder style={MT10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder style={MB10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <ReactPlaceholder style={MT10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder style={MB10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <ReactPlaceholder style={MT10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
        <ReactPlaceholder style={MB10} showLoadingAnimation type="textRow">
          <div></div>
        </ReactPlaceholder>
      </Container>
      
    </main>
  );

  // console.log('post', post);

  const api_usage_lists = post.api_usage_lists;
  const display_api_usage_lists = Object.keys(api_usage_lists).map((key) => {
    return (
      <tr>
        <td style={StyleApiUsed}>{api_usage_lists[key].name}</td>
        <td style={StyleApiUsedRight}>{api_usage_lists[key].amount}</td>
      </tr>
      );
  });

  const conventional_api_event_list = post.conventional_api_event_list;
  const display_conventional_api_event_list = Object.keys(conventional_api_event_list).map((key) => {
    return (
      <tr>
        <td style={StyleApiUsed}>{conventional_api_event_list[key].name}</td>
        <td style={StyleApiUsedRight}>{conventional_api_event_list[key].amount}</td>
      </tr>
      );
  });

  const top_5_product = post.summary.top_5_product;
  const display_top_5_product = Object.keys(top_5_product).map((key) => {
    return (
      <tr>
        <td style={Table3ColTd1}>{top_5_product[key].no}</td>
        <td style={Table3ColTd2}>{top_5_product[key].nama}</td>
        <td style={Table3ColTd3}>{top_5_product[key].revenue}</td>
      </tr>
      );
  });

  const top_5_payment = post.summary.top_5_payment;
  const display_top_5_payment = Object.keys(top_5_payment).map((key) => {
    return (
      <tr>
        <td style={Table3ColTd1}>{top_5_payment[key].no}</td>
        <td style={Table3ColTd2}>{top_5_payment[key].nama}</td>
        <td style={Table3ColTd3}>{top_5_payment[key].percentage}</td>
      </tr>
      );
  });

  const top_5_source = post.summary.top_5_source;
  const display_top_5_source = Object.keys(top_5_source).map((key) => {
    return (
      <tr>
        <td style={Table3ColTd1}>{top_5_source[key].utm_source} / {top_5_source[key].utm_medium} / {top_5_source[key].utm_campaign}</td>
        <td style={Table3ColTd3}>{top_5_source[key].total}</td>
      </tr>
      );
  });

  const server_node_stats = post.summary.server_node_stats;
  var autoIncIdx = 0;
  const display_server_node_stats = Object.keys(server_node_stats).map((key) => {
    autoIncIdx++;
    
    return (
      <tr>
        <td style={StyleDefaultTbodyTD}>{autoIncIdx}</td>
        <td style={StyleDefaultTbodyTD}>{server_node_stats[key].server_name}</td>
        <td style={StyleDefaultTbodyTD}>{server_node_stats[key].total_connected_md}</td>
        <td style={StyleDefaultTbodyTD}>{server_node_stats[key].total_connected_md_api}</td>
      </tr>
      );
  });

  const top_http_referrer = post.summary.top_http_referrer;
  const display_top_http_referrer = Object.keys(top_http_referrer).map((key) => {
    return (
      <tr>
        <td style={Table3ColTd1}>{top_http_referrer[key].orders_referrer}</td>
        <td style={Table3ColTd3}>{top_http_referrer[key].total}</td>
      </tr>
      );
  });


  if (post && (uuid != null)) return (
    <main style={pageStyles}>
      <title>WatZap Report Apps</title>
      <Container style={ContainerShadowCard} fluid="md">
        <Row style={RowRevSumary}>
          <Col style={ColRevSumary}>
            <b style={HeadingLeft}>Hari Ini</b>
            <p style={SubHeadingLeft}>{post.summary.hari_ini.forh4}</p>
          </Col>
          <Col style={VerticalMiddleCol}>
            <b style={TotalRight}>{post.summary.hari_ini.forh2}</b>
          </Col>
        </Row>
        <Row style={RowRevSumary}>
          <Col style={ColRevSumary}>
            <b style={HeadingLeft}>Kemarin</b>
            <p style={SubHeadingLeft}>{post.summary.kemarin.forh4}</p>
          </Col>
          <Col style={VerticalMiddleCol}>
            <b style={TotalRight}>{post.summary.kemarin.forh2}</b>
          </Col>
        </Row>
        <Row style={RowRevSumary}>
          <Col style={ColRevSumary}>
            <b style={HeadingLeft}>7 Hari Terakhir</b>
            <p style={SubHeadingLeft}>{post.summary.tujuhhariterakhir.forh4}</p>
          </Col>
          <Col style={VerticalMiddleCol}>
            <b style={TotalRight}>{post.summary.tujuhhariterakhir.forh2}</b>
          </Col>
        </Row>
        <Row style={RowRevSumary}>
          <Col style={ColRevSumary}>
            <b style={HeadingLeft}>Bulan Ini</b>
            <p style={SubHeadingLeft}>{post.summary.bulanini.forh4}</p>
          </Col>
          <Col style={VerticalMiddleCol}>
            <b style={TotalRight}>{post.summary.bulanini.forh2}</b>
          </Col>
        </Row>
        <Row>
          <Col style={ColRevSumary}>
            <b style={HeadingLeft}>Sisa Quota Coupon</b>
          </Col>
          <Col style={VerticalMiddleCol}>
            <b style={TotalRight}>{post.summary.coupon.sisaquota}</b>
          </Col>
        </Row>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Sales Performance</h4>
        <Row style={RowSummaryConcurrent}>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>Leads</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.paid_ratio.total_orders}</b>
          </Col>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>Paid</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.paid_ratio.total_paid}</b>
          </Col>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>Paid Ratio</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.paid_ratio.total_paid_ratio}</b>
          </Col>
          <Col style={ColSummaryConcurrentLast}>
            <b style={HeaderColSummaryConcurrent}>Unpaid Ratio</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.paid_ratio.total_unpaid_ratio}</b>
          </Col>
        </Row>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Concurrent Connection</h4>
        <Row style={RowSummaryConcurrent}>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>Online</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.total_numbers_connected}</b>
          </Col>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>Broadcast</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.total_numbers_connected_as_broadcast}</b>
          </Col>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>API</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.total_numbers_connected_as_api}</b>
          </Col>
          <Col style={ColSummaryConcurrent}>
            <b style={HeaderColSummaryConcurrent}>Today</b>
            <b style={ContentColSummaryConcurrent}>{post.summary.total_numbers_connected_today}</b>
          </Col>
        </Row>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Server Load</h4>

        <Table style={StyTable} hover>
          <thead>
            <tr>
              <td style={StyleDefaultTheadTHFCH1}>No</td>
              <td style={StyleDefaultTheadTHFCH}>IP Server</td>
              <td style={StyleDefaultTheadTH}>Broadcast</td>
              <td style={StyleDefaultTheadTH}>API</td>
            </tr>
          </thead>
          <tbody>
            {display_server_node_stats}
          </tbody>
        </Table>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>API Usage</h4>

        <Table style={StyTable} hover>
          <tbody>
            {display_api_usage_lists}
          </tbody>
        </Table>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Log Event API (Conventional API)</h4>

        <Table style={StyTable} hover>
          <tbody>
            {display_conventional_api_event_list}
          </tbody>
        </Table>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Last Known CS Reply</h4>

        <Table style={StyTable} hover>
          <tbody>
            <tr>
              <th style={Table3ColTd1}>CS Name</th>
              <th style={Table3ColTd1}>Chats</th>
              <th style={Table3ColTd1}>Message Sent</th>
              <th style={Table3ColTd3}>Last Replied</th>
            </tr>
            <tr>
              <td style={Table3ColTd1}>CS RIDO</td>
              <td style={Table3ColTd1}>{lastknownonline?.lastknownproperty?.totalincomemsgcsnitami}x</td>
              <td style={Table3ColTd1}>{lastknownonline?.lastknownproperty?.totalreplycsnitami}x</td>
              <td style={Table3ColTd3}>{lastknownonline?.lastknownproperty?.lastreplycsnitami}</td>
            </tr>
            <tr>
              <td style={Table3ColTd1}>CS ALMI</td>
              <td style={Table3ColTd1}>{lastknownonline?.lastknownproperty?.totalincomemsgcslinda}x</td>
              <td style={Table3ColTd1}>{lastknownonline?.lastknownproperty?.totalreplycslinda}x</td>
              <td style={Table3ColTd3}>{lastknownonline?.lastknownproperty?.lastreplycslinda}</td>
            </tr>
          </tbody>
        </Table>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Top Selling Product</h4>

        <Table style={StyTable} hover>
          <tbody>
            {display_top_5_product}
          </tbody>
        </Table>
      </Container>


      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Top Payment Method</h4>

        <Table style={StyTable} hover>
          <tbody>
            {display_top_5_payment}
          </tbody>
        </Table>
      </Container>


      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Top Source</h4>

        <Table style={StyTable} hover>
          <tbody>
            {display_top_5_source}
          </tbody>
        </Table>
      </Container>

      <Container style={ContainerShadowCardMt} fluid="md">
        <h4 style={HeaderSummaryConcurrent}>Reference Site</h4>

        <Table style={StyTable} hover>
          <tbody>
            {display_top_http_referrer}
          </tbody>
        </Table>
      </Container>
    </main>
  )
}

export default IndexPage
